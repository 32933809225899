const initBlock = async function( $block ) {

	const $postItemCustom = $block.find( '.post-item--custom' );
	if ( $postItemCustom.length ) {
		const wrapLinesModule = await import( './../modules/text-wrap-each-line' );
		const wrapLines = wrapLinesModule.default;

		wrapLines( $postItemCustom.find( '.post-item__title' ) );
	}
};

themeUtils.loadBlock( initBlock, 'blog-latest', '.section-blog-latest' );
